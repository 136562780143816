<template>
  <div class="project-info-card">
    <div class="item" :class="{skeleton}">
      <div class="thumb">
        <router-link :to="projectType !== 'invest' ? `/reward/${projectSeq}`:`/invest/${investSeq}`" class="img" title="프로젝트로 이동"
                     :style="{ backgroundImage: `url(${$definitions.urls.imgCdn + thumbFileUrl})`, backgroundPosition: mainImagePosition ? mainImagePosition : 'center' }"></router-link>
      </div>
      <div class="texts">
        <div class="badges">
          <template v-if="projectType !== 'invest'">
            <span class="badge badge-purple" v-if="fundingType === 'A'">성공해야 리워드</span>
            <span class="badge badge-purple" v-else-if="fundingType === 'K'">무조건 리워드</span>
          </template>
          <span class="badge badge-dark color-white">{{ projectStatus }}</span>
          <span class="badge badge-purple color-white">{{ investStatus }}</span>
        </div>
        <div class="title ellipsis">
          <router-link class="font-md" :to="projectType !== 'invest' ? `/reward/${projectSeq}`:`/invest/${investSeq}`" :title="projectName">{{ projectName }}</router-link>
        </div>
        <div class="info" :class="[page, $route.query.projectType]">
          <template v-if="page === 'participants'">
            <template v-if="projectType !== 'invest'">
              <div>
                <span class="name">참여금액</span>
                <span>{{ $lib.getNumberFormat(expenseAmt) }}</span>
              </div>
              <div>
                <span class="name">{{ state.projectType === "reward" ? "후원일" : "투자일" }}</span>
                <span>{{ participantDate }}</span>
              </div>
              <div>
                <span class="name">결제 방식</span>
                <span>{{ fundingType === "A" ? `예약 결제(${payType})` : payType }}</span>
              </div>
              <div>
                <span class="name">파트너쉽</span>
                <span :title="partnershipName" class="ellipsis">{{ partnershipName || "(N/A)" }}</span>
              </div>
              <div>
                <span class="name">리워드</span>
                <span :title="rewardTitle" class="ellipsis">{{ rewardTitle }}</span>
              </div>
            </template>
            <template v-else>
              <div>
                <span class="name">투자금액</span>
                <span>{{ $lib.getNumberFormat(subscrAmount) }}</span>
              </div>
              <div>
                <span class="name">참여일</span>
                <span>{{ participantDate }}</span>
              </div>
              <div>
                <span class="name">배당 예정일</span>
                <span>{{ $lib.getDateFormat(distriDate, "yyyy-MM-dd") }}</span>
              </div>
              <div>
                <span class="name">취소일</span>
                <span>{{ cancelDate || "(N/A)" }}</span>
              </div>
            </template>
          </template>
          <template v-else>
            <template v-if="page === 'opens'">
              <div>
                <span class="name">기간</span>
                <span>{{ period }}</span>
              </div>
              <div>
                <span class="name">파트너쉽</span>
                <span class="ellipsis" :title="partnershipName">{{ partnershipName || "(N/A)" }}</span>
              </div>
            </template>
            <template v-else-if="page === 'interests'">
              <div>
                <span class="name">등록일시</span>
                <span>{{ createDate }}</span>
              </div>
              <div>
                <span class="name">참여여부</span>
                <span>{{ investFlag ? "참여" : "참여하지 않음" }}</span>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "componentProjectInfoCard";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    cancelDate: String,
    createDate: String,
    distriAmount: Number, //최종 배당 금액
    distriDate: String, //납입일(배당예정일)
    rewardTitle: String,
    investSeq: Number,
    investStatus: String,
    investFlag: Boolean,
    expenseAmt: Number,
    fundingType: String,
    investorSeq: Number,
    mainImagePosition: String,
    page: String,
    participantDate: String,
    partnershipName: String,
    payType: String,
    period: String,
    projectName: String,
    projectSeq: Number,
    projectStatus: String,
    projectType: String,
    thumbFileUrl: String,
    subscrAmount: Number,
    skeleton: Boolean,
  },
  setup() {
    const component = new Component(() => {
    });

    const state = reactive({});

    return {component, state};
  }
});
</script>

<style lang="scss" scoped>
.project-info-card {
  .item {
    .thumb {
      .img {
        background-color: $colorBackground;
        padding-top: $ratio53Percent;
        background-position: center center;
        background-size: auto 100%;
        background-repeat: no-repeat;
        width: 100%;
      }
    }

    .texts {
      .badges {
        .badge {
          padding: $px4 $px8;

          &:not(:last-child) {
            margin-right: $px4;
          }
        }
      }

      .title {
        margin-top: $px4;
        font-weight: 500;
      }

      .info {
        display: flex;
        font-size: $px12;
        overflow: hidden;
        flex-direction: column;
        gap: $px4 0;
        margin-top: $px4;

        > div {
          display: flex;

          .name {
            display: inline-block;
            width: $px80;
            flex-shrink: 0;
          }
        }

        &.participants {
          height: $px106;

          &.invest {
            height: $px84;
          }
        }

        &.opens, &.interests {
          height: $px41;

          > div {
            .name {
              width: $px70;
            }
          }
        }
      }
    }

    &.skeleton {
      .thumb .img {
        @include skeleton;
      }

      .texts {
        .badges .badge, .title {
          @include skeleton;
        }

        .info > div > span {
          @include skeleton;
        }
      }
    }
  }
}
</style>