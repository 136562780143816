<template>
  <div class="member-detail" :class="{ skeleton: !state.loaded }">
    <div class="header d-flex">
      <div class="info d-flex align-items-center">
        <MemberPicture size="70" :memberSeq="$route.params.memberSeq"/>
        <div class="ml-2" v-if="state.info">
          <span class="font-md d-block font-weight-500">{{ state.info.memberName }}</span>
          <span class="font-sm">{{ state.info.memberId }}</span>
        </div>
      </div>
      <div class="tabs">
        <router-link class="btn btn-default" v-for="(m, idx) in tabs" :key="idx" :class="{active: m.active}"
                     :to="`/new-admin/member/members/${$route.params.memberSeq}` + (m.name && `/${m.name}${m.defaultQuery ? '?'+m.defaultQuery : ''}`)">
          {{ m.title }}
        </router-link>
      </div>
    </div>
    <div class="body">
      <MemberInfo v-if="!$route.params.tab" :info="state.info" :skeleton="!state.loaded"/>
      <MemberProjects v-else-if="['participants', 'opens', 'interests'].includes($route.params.tab)" :tab="$route.params.tab"/>
      <MemberCoupons v-else-if="$route.params.tab === 'coupons'"/>
    </div>
  </div>
</template>
<script>
import mixin from "@/scripts/mixin";
import http from "@/scripts/http";
import {computed, defineComponent, reactive} from "@vue/composition-api";
import PaginationJpa from "@/components/PaginationJpa";
import router from "@/scripts/router";
import MemberInfo from "@/pages/admin/member/MemberInfo";
import MemberPicture from "@/components/MemberPicture";
import NoData from "@/components/NoData";
import MemberProjects from "@/pages/admin/member/MemberProjects";
import MemberCoupons from "@/pages/admin/member/MemberCoupons";

function Component(initialize) {
  this.name = "pageAdminMembersMemberDetail";
  this.initialize = initialize;
}

export default defineComponent({
  components: {MemberCoupons, MemberProjects, NoData, MemberPicture, MemberInfo, PaginationJpa},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      state.loaded = false;

      http.get(`/api/admin/members/${router.app.$route.params.memberSeq}`).then(({data}) => {
        state.loaded = true;
        state.info = data.body;
      });
    });

    const state = reactive({
      loaded: false,
    });

    const tabs = computed(() => {
      const tab = router.app.$route.params.tab;

      return [
        {
          title: "계정 정보",
          name: "",
          active: !tab,
        },
        {
          title: "펀딩 프로젝트",
          name: "participants",
          active: tab === "participants",
          defaultQuery: "projectType=reward",
        },
        {
          title: "진행 프로젝트",
          name: "opens",
          active: tab === "opens",
        },
        {
          title: "관심 프로젝트",
          name: "interests",
          active: tab === "interests",
        },
        {
          title: "쿠폰 현황",
          name: "coupons",
          active: tab === "coupons",
        },
      ];
    });

    return {component, state, tabs};
  }
});
</script>
<style lang="scss" scoped>
.member-detail {
  font-size: $px14;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $px20;

    .total {
      padding-top: $px7;

      i {
        margin-right: $px5;
      }
    }

    .tabs {
      .btn {
        font-size: $px14;
      }
    }

    .search {
      width: $px370;

      .row {
        > div {
          select {
            position: relative;
            border-radius: $px4 0 0 $px4;
            left: $px1;

            &:focus {
              position: relative;
              z-index: 1;
            }
          }

          input[type=text] {
            border-radius: 0;
          }

          .btn {
            border-radius: 0 $px4 $px4 0;
            height: 100%;
          }
        }
      }
    }
  }

  .body {
    padding-bottom: $px30;

    table {
      margin-bottom: $px35;

      tr {
        th, td {
          padding-left: $px20;
          padding-right: $px20;
        }

        th {
          background: $colorBackground;
          border-bottom: 0;
        }
      }
    }
  }

  &.skeleton {
    .header {
      .total span {
        @include skeleton;
      }
    }

    .body {
      table tr {
        th, td {
          a, span {
            @include skeleton;
          }
        }
      }

      &::v-deep {
        .pagination {
          .btn {
            @include skeleton;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .header {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;

      .tabs {
        margin-top: $px15;
        max-width: 100%;
        white-space: nowrap;
        overflow: auto;

        .btn {
          font-size: $px12;
        }
      }
    }
  }
}
</style>