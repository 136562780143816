<template>
  <div class="member-projects" :class="{skeleton: !state.loaded}">
    <div class="top d-flex justify-content-lg-between align-items-lg-center">
      <div class="total">
        <span>총 {{ state.total }}개</span>
      </div>
      <div class="tabs thin-scrollbar">
        <button class="btn font-xs" :class="$route.query.projectType === t.name ? 'btn-purple': 'btn-default'" v-for="(t, idx) in computedTabs" :key="idx" @click="setProjectType(t.name)">{{ t.title }}</button>
      </div>
    </div>
    <div class="wrapper" v-if="!state.loaded || state.list.length">
      <div class="row">
        <div class="item col-lg-3" v-for="(p, idx) in state.list" :key="idx">
          <ProjectInfoCard
              :createDate="p.createDate"
              :skeleton="p.skeleton || !state.loaded"
              :expenseAmt="p.expenseAmt"
              :fundingType="p.fundingType"
              :investSeq="p.investSeq"
              :investorSeq="p.investorSeq"
              :investFlag="p.investFlag"
              :investStatus="p.investStatus"
              :page="tab"
              :participantDate="p.participantDate"
              :partnershipName="p.partnershipName"
              :payType="p.payType"
              :period="p.period"
              :projectName="p.projectName"
              :projectSeq="p.projectSeq"
              :projectStatus="p.projectStatus"
              :projectType="$route.query.projectType"
              :subscrAmount="p.subscrAmount"
              :cancelDate="p.cancelDate"
              :distriDate="$lib.getDateFormat(p.distriDate, 'yyyy-MM-dd')"
              :thumbFileUrl="p.thumbFileUrl"
              :rewardTitle="p.rewardTitle"
          />
        </div>
      </div>
      <PaginationJpa :change="load" :total="state.total" :pageable="state.pageable" className="btn-purple" v-if="state.pageable.size"/>
    </div>
    <NoData v-else/>
  </div>
</template>

<script>
import {computed, defineComponent, reactive, watch} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import router from "@/scripts/router";
import ProjectInfoCard from "@/components/ProjectInfoCard";
import lib from "@/scripts/lib";
import http from "@/scripts/http";
import NoData from "@/components/NoData";
import PaginationJpa from "@/components/PaginationJpa";

function Component(initialize) {
  this.name = "pagesAdminMemberMemberProjects";
  this.initialize = initialize;
}

export default defineComponent({
  components: {PaginationJpa, NoData, ProjectInfoCard},
  mixins: [mixin],
  props: {
    tab: String,
  },
  setup(props) {
    const component = new Component(() => {
      !router.app.$route.query.projectType && router.replace({query: {projectType: "reward"}});
      load(router.app.$route.query.page && router.app.$route.query.page - 1);
    });

    const state = reactive({
      args: {
        page: 0,
        size: 20,
      },
      loaded: false,
      list: [],
      pageable: {
        page: 0,
        size: 0,
      },
      total: 0
    });

    const computedTabs = computed(() => {
      const tabs = [{
        name: "reward",
        title: "후원형",
      }, {
        name: "invest",
        title: "증권형",
      }];

      props.tab !== "interests" && tabs.push({
        name: "mock",
        title: "모의투자",
      });

      return tabs;
    });

    const load = (page) => {
      const memberSeq = router.app.$route.params?.memberSeq;
      const args = {
        ...state.args,
        projectType: router.app.$route.query.projectType,
      };

      if (page && window.Number(page) !== window.Number(router.app.$route.query.page - 1)) {
        router.push({
          query: {
            ...router.app.$route.query,
            page: page + 1,
          }
        });
      }
      page && (args.page = page);

      !args.projectType && (args.projectType = "reward");
      state.loaded = false;
      state.list = [];
      state.total = 0;
      state.pageable.size = 0;
      state.pageable.page = 0;

      for (let i = 0; i < 4; i += 1) {
        state.list.push({
          skeleton: true,
          rewardTitle: "Please wait a moment",
          investStatus: "wait a moment",
          expenseAmt: 100000,
          fundingType: "wait a moment",
          participantDate: "0000-00-00 00:00:00",
          payType: "wait a moment",
          projectName: "Please wait a moment",
          projectStatus: "wait a moment",
          projectType: "wait a moment",
          subscrAmount: 100000,
        });
      }

      http.get(`/api/admin/members/${memberSeq}/${props.tab}`, args).then((res) => {
        if (res.config.url.includes(props.tab)) {
          state.loaded = true;
          state.list = res.data.body.content;
          state.total = res.data.body.total;
          state.pageable = res.data.body.pageable;
        }
      });
    };

    const setProjectType = (projectType) => {
      if (router.app.$route.query.projectType === projectType) {
        return;
      }

      const query = lib.getRenewed(router.app.$route.query);
      query.projectType = projectType || "reward";
      router.push({query});
    };

    watch(() => router.app.$route.query.projectType, (next, prev) => {
      if (next && prev) {
        load();
      }
    });

    watch(() => props.tab, () => {
      if (router.app.$route.query.projectType !== "reward") {
        router.push({
          query: {
            projectType: "reward"
          }
        });
      }
      load(0, true);
    });

    return {component, state, computedTabs, load, setProjectType};
  }
});
</script>

<style lang="scss" scoped>
.member-projects {
  .top {
    .tabs {
      .btn {
        border-radius: $px100;
      }
    }
  }

  .wrapper {
    .row {
      gap: $px30 0;
      margin-top: $px30;
    }

    .pagination {
      margin-top: $px30;
    }
  }

  .no-data {
    padding: $px70 0;
  }

  &.skeleton {
    .top {
      .total, .tabs .btn {
        @include skeleton;
      }
    }
  }

  @media (max-width: 991px) {
    .top {
      flex-direction: column;

      .tabs {
        margin-top: $px15;
      }
    }
  }
}
</style>