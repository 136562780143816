<template>
  <div class="member-coupons" :class="{skeleton: !state.loaded}">
    <div class="table-responsive" v-if="state.list.length || !state.loaded">
      <table class="table table-hover">
        <thead>
        <tr>
          <th>
            <span>쿠폰아이디</span>
          </th>
          <th>
            <span>쿠폰명</span>
          </th>
          <th>
            <span>사용가능기간</span>
          </th>
          <th>
            <span>사용가능 금액</span>
          </th>
          <th>
            <span>사용가능 프로젝트</span>
          </th>
          <th>
            <span>사용내역</span>
          </th>
          <th>
            <span>쿠폰 삭제</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(c, idx) in  state.list" :key="idx">
          <td>
            <span>{{ c.couponId }}</span>
          </td>
          <td>
            <span>{{ c.couponName }}</span>
          </td>
          <td>
            <span>{{ c.usablePeriod }}</span>
          </td>
          <td>
            <span>{{ c.usablePoint }}</span>
          </td>
          <td>
            <button class="btn btn-default" @click="openProjects(c.usableProjects)">목록보기</button>
          </td>
          <td>
            <button class="btn btn-default" @click="openHistories(c.couponHistories)">목록보기</button>
          </td>
          <td>
            <button class="btn btn-default" @click="deleteCoupon(c.couponId)">쿠폰삭제</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <NoData v-else/>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import http from "@/scripts/http";
import router from "@/scripts/router";
import NoData from "@/components/NoData";
import store from "@/scripts/store";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "pagesAdminMemberMemberCoupons";
  this.initialize = initialize;
}

export default defineComponent({
  components: {NoData},

  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      loaded: false,
      list: [],
      total: 0,
      pageable: {
        page: 0,
        size: 0,
      },
    });

    const load = (page) => {
      const memberSeq = router.app.$route.params?.memberSeq;
      const args = {
        page: 0,
        size: 20
      };

      page && (args.page = page);
      state.loaded = false;
      state.list = [];
      state.total = 0;
      state.pageable.page = 0;
      state.pageable.size = 0;

      for (let i = 0; i < 5; i += 1) {
        state.list.push({
          couponId: "0000000",
          couponName: "Please wait a moment",
          usablePeriod: "0000-00-00 ~ 0000-00-00",
          usablePoint: "0000",
        });
      }

      http.get(`/api/admin/members/${memberSeq}/coupons`, args).then(({data}) => {
        state.loaded = true;
        state.list = data.body.content;
        state.total = data.body.total;
        state.pageable = data.body.pageable;
      });
    };

    const openProjects = (list) => {
      store.commit("openModal", {
        name: "TableList",
        params: {
          list,
          title: "사용가능한 프로젝트 목록",
          head: [{
            title: "프로젝트 번호",
            name: "projectSeq"
          }, {
            title: "프로젝트명",
            name: "projectName"
          }, {
            title: "프로젝트 기간",
            name: "period"
          }],
        }
      });
    };

    const openHistories = (list) => {
      store.commit("openModal", {
        name: "TableList",
        params: {
          list,
          title: "사용내역",
          head: [{
            title: "쿠폰 ID",
            name: "couponId",
          }, {
            title: "쿠폰 금액",
            name: "couponPoint",
            type: "number"
          }, {
            title: "할당일",
            name: "regiDate",
            type: "date"
          }, {
            title: "사용처",
            name: "useDesc",
          }],
        }
      });
    };

    const deleteCoupon = (couponId) => {
      store.commit("confirm", {
        message: "쿠폰을 삭제하시겠습니까?",
        allow() {
          const memberSeq = router.app.$route.params?.memberSeq;
          http.delete(`/api/admin/members/${memberSeq}/coupons/${couponId}`).then(() => {
            store.commit("setSwingMessage", "쿠폰을 삭제했습니다.");
            load();
          }).catch(httpError());
        }
      });
    };

    return {component, state, openProjects, openHistories, deleteCoupon};
  }
});
</script>

<style lang="scss" scoped>
.member-coupons {
  .table-responsive {
    .table {
      thead tr {
        text-align: center;
        background: #f7f7f7;
      }

      tbody tr td {
        text-align: center;
        vertical-align: middle;

        .btn {
          padding: $px4 $px16;
          font-size: $px10;
        }
      }
    }
  }

  &.skeleton {
    .table-responsive .table {
      thead, tbody {
        tr {
          td, th {
            span, .btn {
              @include skeleton;
            }
          }
        }
      }
    }
  }
}
</style>