<template>
  <div class="member-info font-sm" :class="{skeleton}">
    <div class="row" v-for="(i, idx1) in computedItems" :key="idx1">
      <div class="title col-lg-2 font-md">
        <b>{{ i.title }}</b>
      </div>
      <div class="col-lg-10 row" v-if="info">
        <template v-for="(c, idx2) in i.children">
          <div :key="idx2" class="item col-lg-4" :class="c.type">
            <label :for="getId(c.name)">{{ c.title }}</label>
            <template v-if="c.type === 'phone'">
              <Phone :value.sync="info[c.name]" :id="getId(c.name)" :readonly="c.readonly" className="border-focus-purple"/>
            </template>
            <template v-else-if="c.type === 'address'">
              <div class="d-flex flex-column">
                <div class="d-flex">
                  <Number :value.sync="info.memberPost || '(N/A)'" :readonly="c.readonly" className="border-focus-purple"/>
                  <button class="btn btn-default font-xs text-nowrap ml-2" :disabled="c.readonly">우편번호</button>
                </div>
                <div>
                  <input type="text" class="form-control border-focus-purple" :value="info.memberAddr1 || '(N/A)'" readonly/>
                </div>
                <div>
                  <input type="text" class="form-control border-focus-purple" :value="info.memberAddr2 || '(N/A)'" readonly/>
                </div>
              </div>
            </template>
            <template v-else-if="c.type === 'number'">
              <Number :value.sync="info[c.name]" :id="getId(c.name)" :noComma="c.noComma" :readonly="c.readonly" className="border-focus-purple"/>
            </template>
            <template v-else-if="c.type === 'checkbox'">
              <div class="d-flex align-items-center">
                <div class="form-check mr-2" v-for="(f, idx3) in c.items" :key="idx3">
                  <input class="form-check-input" type="checkbox" :id="getId(f.name)" :checked="f.checked" :disabled="f.disabled">
                  <label class="form-check-label" :for="getId(f.name)">{{ f.title }}</label>
                </div>
              </div>
            </template>
            <template v-else>
              <input :type="c.type" class="form-control border-focus-purple" :value="info[c.name] || '(N/A)'" :readonly="c.readonly">
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import Number from "@/components/Number";
import Phone from "@/components/Phone";

function Component(initialize) {
  this.name = "pagesAdminMemberMemberInfo";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Phone, Number},
  mixins: [mixin],
  props: {
    info: Object,
    skeleton: Boolean
  },
  setup(props) {
    const component = new Component(() => {
    });

    const state = reactive({});

    const computedItems = computed(() => {
      return [
        {
          title: "회원 정보",
          children: [{
            title: "가입 구분",
            type: "text",
            name: "joinType",
            readonly: true,
          }, {
            title: "회원 구분",
            type: "text",
            name: "memberTypeName",
            readonly: true,
          }, {
            title: "회원 상태",
            type: "text",
            name: "memberStatusName",
            readonly: true,
          }, {
            title: "투자자 인증 여부",
            type: "text",
            name: "investorStatus",
            readonly: true,
          }, {
            title: "회원 코드",
            type: "number",
            noComma: true,
            name: "memberSeq",
            readonly: true,
          }, {
            title: "아이디(이메일)",
            type: "text",
            name: "memberId",
            readonly: true,
          }, {
            title: "생년월일",
            type: "text",
            name: "birth",
            readonly: true,
          }, {
            title: "휴대폰 번호",
            type: "phone",
            name: "mobile",
            readonly: true,
          }, {
            title: "가상계좌 잔액",
            type: "number",
            name: "accountBalance",
            readonly: true,
          }, {
            title: "소개",
            type: "text",
            name: "introduction",
            readonly: true,
          }, {
            title: "수신 여부",
            type: "checkbox",
            name: "receive",
            readonly: true,
            items: [{
              title: "이메일",
              checked: props?.info?.emailRecv === "Y",
              name: "emailRecv",
              disabled: true,
            }, {
              title: "SMS",
              checked: props?.info?.smsRecv === "Y",
              name: "smsRecv",
              disabled: true,
            },]
          }, {
            title: "주소",
            type: "address",
            name: "memberAddr",
            readonly: true,
          }]
        }, {
          title: "기업정보",
          children: [{
            title: "기업명",
            name: "corporateName",
            type: "text",
            readonly: true,
          }, {
            title: "대표자명",
            name: "ceoName",
            type: "text",
            readonly: true,
          }, {
            title: "사업자등록번호",
            name: "businessNum",
            type: "text",
            readonly: true,
          }, {
            title: "법인등록번호",
            name: "corporateNum",
            type: "text",
            readonly: true,
          }, {
            title: "팩스번호",
            name: "businessFax",
            type: "text",
            readonly: true,
          }, {
            title: "홈페이지",
            name: "homepage",
            type: "text",
            readonly: true,
          }, {
            title: "기업주소",
            name: "businessAddr",
            type: "address",
            readonly: true,
          }]
        },
        {
          title: "가입 정보",
          children: [{
            title: "가일일(IP)",
            name: "joinDate",
            type: "text",
            readonly: true,
          }, {
            title: "최근 접속일(IP)",
            name: "latestLoginDate",
            type: "text",
            readonly: true,
          }, {
            title: "로그인 횟수",
            name: "loginCnt",
            type: "text",
            readonly: true,
          }, {
            title: "최근 정보 수집일(IP)",
            name: "updateDate",
            type: "text",
            readonly: true,
          },]
        }
      ];
    });

    const getId = (word) => {
      return component.name + word.charAt(0).toUpperCase() + word.slice(1);
    };

    return {component, state, computedItems, getId};
  }
});
</script>

<style lang="scss" scoped>
.member-info {
  > .row {
    .title {
      margin-bottom: $px10;
    }

    .col-lg-10 {
      .item {
        &.address {
          width: 100%;
          max-width: 100%;
          flex-basis: 100%;

          > div {
            gap: $px10 0;

            .btn {
              padding: $px8 $px80;
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: $px10;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: $px30;
    }
  }

  &.skeleton {
    > .row {

    }
  }

  @media (max-width: 991px) {
    > .row {
      .col-lg-10 {
        .item {
          &.address {

            > div {
              .btn {
                padding: $px8 $px20;
              }
            }
          }
        }
      }
    }
  }
}
</style>